import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Container from '../../components/Container';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { useIntl } from 'gatsby-plugin-intl';
import SmartStockBanner from '../../components/SmartStockBanner';
import SmartStockPortfolios from '../../components/SmartStockPortfolios';
import WhatYouGet from '../../components/StockTradingWhatYouGet';
import InvestmentOptions from '../../components/InvestmentOptions';
import Blank from '../../components/Blank';
import ProductEntry from '../../components/AssetAllocationProduct';

// 渐变背景
const GradientBackground = ({ to = 'bottom', marginTop = [0, 0, 0] }) => {
  return (
    <div
      css={css`
        z-index: -5;
        // transform: rotate(-180deg);
        background-image: linear-gradient(
          to ${to},
          rgba(242, 242, 242, 0),
          #f2f2f2
        );
        width: 100%;
        height: 146px;
        margin-top: ${marginTop[0]}px;
        ${Tablet} {
          height: 305px;
          margin-top: ${marginTop[1]}px;
        }
        ${Desktop} {
          height: 514px;
          margin-top: ${marginTop[2]}px;
        }
      `}
    ></div>
  );
};

const SmartStockPage = () => {
  const intl = useIntl();
  return (
    <Layout footerBand={false} footerBlank>
      <SEO
        title={intl.formatMessage({ id: 'individuals.smart-stock.SEO.title' })}
        description={intl.formatMessage({
          id: 'individuals.smart-stock.SEO.description',
        })}
        keywords={intl.formatMessage({
          id: 'individuals.smart-stock.SEO.keywords',
        })}
      />
      <SmartStockBanner />
      <Container
        css={css`
          ${Desktop} {
            padding: 0;
          }
        `}
      >
        <div
          css={css`
            color: #161616;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.5;
            margin: 0 auto;
            text-align: center;
            margin-bottom: 80px;
            margin-top: 107px;
            width: ${{
              en: '100%',
              'zh-cn': '250px',
              'zh-hk': '250px',
            }[intl.locale]};

            ${Tablet} {
              text-align: center;
              font-size: 36px;
              font-weight: 500;
              // line-height: 1.33;
              width: ${{
                en: '100%',
                'zh-cn': '330px',
                'zh-hk': '330px',
              }[intl.locale]};
            }
            ${Desktop} {
              font-size: 36px;
              font-weight: 500;
              // line-height: 0.92;
              width: 100%;
            }
          `}
        >
          {intl.formatMessage({
            id: 'individuals.smart-stock.title1',
          })}
        </div>
        <ProductEntry name='SGU' logoWidthList={[231, 340, 494]} />
        <Blank height={[80, 130, 140]} />
        <ProductEntry name='SG' logoWidthList={[231, 214, 429.16]} />
        <Blank height={[100, 150, 144]} />
      </Container>
      <Container
        css={css`
          ${Desktop} {
            padding: 0;
          }
        `}
      >
        <div
          css={css`
            color: #161616;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.5;
            margin-bottom: 80px;
            width: 293px;
            ${Tablet} {
              font-size: 36px;
              font-weight: 500;
              line-height: 1.33;
              width: 100%;
            }
            ${Desktop} {
              font-size: 36px;
              font-weight: 500;
              line-height: 0.92;
              width: 100%;
            }
          `}
        >
          {intl.formatMessage({
            id: 'individuals.smart-stock.title2',
          })}
        </div>
        <SmartStockPortfolios name='us' productList={[1, 2, 3, 9, 10, 12]} />
        <Blank height={[50, 100, 150]} />
        <SmartStockPortfolios
          name='hk'
          CardCount={4}
          productList={[4, 13, 7, 8, 11]}
        />
        <Blank height={[50, 100, 150]} />
        <SmartStockPortfolios name='cn' CardCount={2} productList={[5, 6]} />
        <Blank height={[100, 150, 200]} />
        <div
          css={css`
            color: #161616;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            width: 293px;
            ${Tablet} {
              font-size: 36px;
              font-weight: 500;
              line-height: 1.33;
              width: 100%;
            }
            ${Desktop} {
              font-size: 36px;
              font-weight: 500;
              line-height: 0.92;
              width: 100%;
            }
          `}
        >
          {intl.formatMessage({
            id: 'individuals.smart-stock.what-you-get',
          })}
        </div>
        <div
          css={css`
            color: #333333;
            margin-top: 21px;
            font-size: 20px;
            line-height: 1.33;
            width: 293px;
            ${Tablet} {
              font-size: 20px;
              line-height: 1.33;
              width: 484px;
            }
            ${Desktop} {
              font-size: 20px;
              line-height: 27px;
              width: 100%;
            }
          `}
        >
          {intl.formatMessage({
            id: 'individuals.smart-stock.sub-title',
          })}
        </div>
        <Blank height={[20, 48, 50]} />
        <WhatYouGet advantages={['commission', 'flat-fee', 'IMPL']} />
      </Container>
      {/* <GradientBackground marginTop={[158, 100, -398]} /> */}
      {/* <Blank height={[0, 0, 84]} />
      <Container
        css={css`
          ${Tablet} {
            padding: 0 30px;
          }
          ${Desktop} {
            padding: 0px;
          }
        `}
      >
        <div
          css={css`
            color: #161616;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            margin-top: -204px;
            ${Tablet} {
              font-size: 36px;
              font-weight: 500;
              line-height: 1.33;
              margin-top: -255px;
            }
            ${Desktop} {
              font-size: 36px;
              font-weight: bold;
              line-height: normal;
              margin-top: 0;
            }
          `}
        >
          {intl.formatMessage({
            id: 'individuals.investment-options.other-title',
          })}
        </div>
        <Blank height={[20, 50, 50]} />
      </Container>

      <div
        css={css`
          width: 100%;
          margin-top: -130px;
          // padding-left: 27px;
          ${Tablet} {
            // padding-left: 30px;
            max-width: 970px;
            margin-top: -167px;
          }
          ${Desktop} {
            padding-left: 117px;
            max-width: 1440px;
            margin-top: 0;
          }
        `}
      >
        <InvestmentOptions doNotShow='SmartStock' />
      </div>
      <Blank height={[-10, 90, 134]} /> */}
    </Layout>
  );
};

export default SmartStockPage;
