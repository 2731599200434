import { css } from '@emotion/core';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const SmartStockBanner = () => {
  const intl = useIntl();

  // const data = useStaticQuery(graphql`
  //   query {
  //     Banner: file(relativePath: { eq: "smart-stock/banner.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1200) {
  //           ...GatsbyImageSharpFluid_noBase64
  //         }
  //       }
  //     }
  //   }
  // `);
  return (
    <div
      css={css`
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
      `}
    >
      <div
        css={css`
          flex: 0 0 auto;
          height: 728px;
          display: flex;
          flex-direction: column;
          background-color: #ee2f79;
          align-items: center;
          position: relative;
          width: 100%;
          ${Tablet} {
            // width: 768px;
            display: flex;
            // flex-direction: row;
            height: 750px;
            position: relative;
            align-items: flex-start;
          }
          ${Desktop} {
            // width: 1440px;
            height: 615px;
            display: flex;
            flex-direction: row;
          }
        `}
      >
        {/* 左字 */}
        <div
          css={css`
            color: #ffffff;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            // padding: 25px 23px 0 27px;
            flex: 1;
            padding-top: 62px;
            ${Tablet} {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
              padding: 73px 0 0 30px;
            }
            ${Desktop} {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
              padding: 155px 48px 0 117px;
            }
          `}
        >
          <span
            css={css`
              font-size: 32px;
              font-weight: bold;
              line-height: 44px;
              width: 100%;
              width: 325px;
              ${Tablet} {
                font-size: 32px;
                line-height: 44px;
                font-weight: bold;
                width: 100%;
              }
              ${Desktop} {
                font-size: 48px;
                font-weight: bold;
                line-height: 1.04;
                width: 480px;
              }
            `}
          >
            {intl.formatMessage({ id: 'individuals.smart-stock.banner-title' })}
          </span>
          <span
            css={css`
              font-size: 18px;
              font-weight: normal;
              line-height: 24px;
              margin-top: 20px;
              width: 291px;
              ${Tablet} {
                font-size: 18px;
                line-height: 24px;
                margin-top: 21px;
                width: 291px;
              }
              ${Desktop} {
                font-size: 24px;
                line-height: 1.38;
                margin-top: 43px;
                width: 468px;
              }
            `}
          >
            {intl.formatMessage({
              id: 'individuals.smart-stock.banner-description',
            })}
          </span>
        </div>
        {/* 右图 */}
        <div
          css={css`
            background: url('/product/banner-right.png') center no-repeat;
            background-size: cover;
            // width: 100%;
            // height: 784px;
            display: flex;
            // flex:1;
            position: relative;
            color: #333333;
            width: 100%;
            height: 426px;
            ${Tablet} {
              background-image: url('/product/banner-right-ipad.png');
              background-size: cover;
              height: 460px;
            }
            ${Desktop} {
              height: 615px;
            }
          `}
        ></div>
      </div>
    </div>
  );
};

export default SmartStockBanner;
